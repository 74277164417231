
$fonts: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
$header_fonts: Avenir, $fonts;

// colors

/* this green is approx what is in the logo (taken from image picker) */
$brooklyn_green: #6db34b;
$bootstrap_theme_green_gradient_upper: #5cb85c;
$bootstrap_theme_green_gradient_lower: #48a648;
$vibrant_bg_green: $bootstrap_theme_green_gradient_lower;
$vibrant_fg_green: #483;

$white_fa: #fafafa;
$white_f5: #f5f5f5;
$white_ee: #eee;
$white_dd: #ddd;  // for borders and tooltip bg
$gray_aa: #aaa;
$gray_88: #888;
$gray_66: #666;

$blackish: #393939;

$bootstrap_blue_border: #428bca;

// roles for colors

$text_color: $blackish;

$a_color: $vibrant_fg_green;
$a_hover_color: $brooklyn_green;

//$code_bg_color: #e8eded;
$code_bg_color: $white_f5;

$footer_icon_gray: $gray_88;
$fonts: "myriad-pro", "myriad pro", "myriad-pro-1", "Helvetica Neue", Helvetica, Arial, sans-serif;
$header_fonts: "Open Sans", $fonts;


// colors

$cloudsoft_green: #24A27D;
$cloudsoft_blue: #102752;

$blueish: #102752;
$whitish: #f5f6fa;

$whitish_1darker: #f0f1f8;
$whitish_2darker: #e8eaf2;

// roles for colors

$text_color: $blueish;
$background_color: $whitish;
 
$a_color: $cloudsoft_green;
$a_hover_color: $cloudsoft_green;


$navbar_height: 50px;
$sidebar_width_perc: 25%;
$sidebar_pl: 24px;
$sidebar_pr: 6px;
$sidebar_pt: 160px;
$sidebar_menu_mt: 48px;

html, body { min-height: 100%; }

.navbar-brand > img {
    margin-top: 0 !important;
}

.navbar a.navbar-btn {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    padding: 6px 12px;
}
body {
    padding-top: 0px;
    font-weight: 400;
    padding-bottom: 0px;
}

a:hover {
    text-decoration: underline;
}

.chevron-icon{
    cursor: pointer;
}

// headers

#content_container h1 {
  // allow this to go full width
  max-width: 100%;
  font-variant: normal;
  margin-top: -16px;
  padding-bottom: 6px;
  margin-top: 32px;
  margin-bottom: 24px;
  border-bottom: 1px dotted $gray_aa;
  font-weight: 300;
  font-size: 42px;
  text-transform: uppercase;
}
h2, h3 {
  font-weight: 500;
}
h4, h5, h6 {
  font-weight: 600;
}
code, pre {
  font-size: 90%;
}


// layout

.second-footer { border-bottom-color: $cloudsoft_green}

.navbar {
    margin-bottom: 0;
    // making navbar fixed to top
    height: $navbar_height;
    position: fixed;
    width: 100%;
}
.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 13px;
}
.navbar-nav > li.search > a {
    padding-top: 12px;
    padding-bottom: 10.5px;
}
.navbar-right.secondary-links { margin-left: 2em; }
// .footer { margin-top: 0; }
.container.footer{
width: 100%;
}
#content_container{
    min-height: 300px;
}
#main_container_inner {
    margin-top: $navbar_height;
    width: 100%;
    height: 100%;
    min-height: 60vh;
    display: flex;
}
#left_container {
    vertical-align: top;
    width: $sidebar_width_perc;
    height: calc(100vh - #{$navbar_height});
    min-height: calc(100vh - #{$navbar_height});
    max-height: calc(100vh - #{$navbar_height});
    display: inline-block;
    background-color: $cloudsoft_blue;
    color: $whitish;
    a, a:hover, a:focus {
        color: $whitish;
    }
    border-top: 4px solid $cloudsoft_green;
    border-bottom: 4px solid $cloudsoft_green;
    padding-right: 6px;

    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
#left_container_inner {
    flex: 1 1 auto;
}

#right_container {
    vertical-align: top;
    width: calc(100% - #{$sidebar_width_perc});

    height: calc(100vh - #{$navbar_height});
    min-height: calc(100vh - #{$navbar_height});
    max-height: calc(100vh - #{$navbar_height});

    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
#right_container_inner{
    flex: 1 1 auto;
	width: 100%;
    padding-top: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 64px;

    img {
        max-width: 100%;
    }
}

// menu

#side-menu {
    padding-top: $sidebar_pt;
    padding-bottom: 96px;
    padding-left: $sidebar_pl;
    padding-right: $sidebar_pr;
    overflow-x: auto;
    font-weight: 300;
    // all adjustments plus hardcoded 30px to account for possible scrollbar displaying
    width: 100%;  //calc(#{$sidebar_width_perc} - #{$sidebar_pl} - #{$sidebar_pr});
    scrollbar-width: thin;
}
.amp-docs-menu-block {
    a, a:hover, a:focus {    
        color: $text_color;
        text-decoration: none;
    }
    &.depth-0 {
      margin-top: 16px; 
    }
}
.amp-docs-menu-item {
  white-space: nowrap;
  &.active a {
    font-weight: bold;
  }
}


// header things?

/*
h1#content-top {
    border-bottom: medium none;
    margin-top: 25px;
    position: absolute;
    width: auto;
}
*/

.header-image-splash{
	background-repeat: no-repeat;
	background-position: center top;
	opacity: 0.3;
	height: 1050px;
    position: absolute;
    top: 0px;
    width: 100%;
}
.header-image-splash-fade{
	background: -moz-linear-gradient(top, rgba(153,153,153,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(153,153,153,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(153,153,153,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00999999', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	
    position: absolute;
    top: 0px;
    width: 100%;
	height: 1050px;
}
.underSplash{
	position: absolute;
    width: 100%;
}
.header-logo{
	background-repeat: no-repeat;
	background-position: center top;
	height: 84px;
    width: 300px;
    display: inline-block;
}
#zone-merge-header {
    .header-logo {
        margin-top: 24px;
    }
    h1 {
        font-size: 64px;
        padding-bottom: 128px;
        border-bottom: none;
    }
}


// lists - based on cloudsoft common blueprints-list-item TODO refactor that to share this

ul.card-list-view {
    li {
        a {
            color: $text_color;

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }

            &:hover,
            &:focus {
                .list-item {
                    background: $whitish;
                    // background: lighten(@primary-500, 60%);
                }
            }

            &:active {
                .list-item {
                    background: $whitish_1darker;
                    // background: lighten(@primary-500, 55%);
                }
            }
        }

        .list-item {
            padding: 20px;
            background: white;
            width: 100%;
            display: block;
        }
    }

    li:not(:last-child) .list-item {
        border-bottom: 1px solid #E1E1E1;
    }

    .float-right {
        float: right;
        text-align: right;
    }

    .description {
        display: block;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 300;
        font-size: 90%;
        margin-top: 2px;

    }

    .float-left {
        float: left;
    }
}



// misc

.clipboard_button {
    color: $cloudsoft_blue;
}

html footer {
    height: auto !important;
}
.footer .cloudsoft-logo:hover .color-primary {
    fill: $cloudsoft_blue;
}
.footer .cloudsoft-logo:hover .color-accent {
    fill: $cloudsoft_green;
}

// weird yaml formatting bug
.language-yaml span.err {
  border: none;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: $white_f5;
    color: $cloudsoft_green;
    background-image: none;
}

#simple_google {
    input {
        font-size: 80%;
        font-weight: 300;
        padding-left: 1.25ex;
        padding-right: 1.25ex;
        &::placeholder {
            font-weight: 100;
        }
    }
}


.language-hcl {
  span.err {
    border: none !important;   // don't render HCL highlight errors, as the HCL parser is poor even before the maeztro extensions
  }
}


.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    object-fit: contain;
}
.centered-25 {
    @extend .centered;
    max-width: 25% !important;
}
.centered-50 {
    @extend .centered;
    max-width: 50% !important;
}
.centered-75 {
    @extend .centered;
    max-width: 75% !important;
}
.centered-100 {
    @extend .centered;
    max-width: 100% !important;
}

